class LazyLoader {
	constructor(
		selector = "[data-lazy]",
		{ threshold = "150px" } = { threshold: "150px" }
	) {
		/**
		 * Nepovinné parametry
		 **/
		this.threshold = threshold;

		/**
		 * Pomocný element pro zpracování noscriptů
		 **/
		this.fragment = document.createElement("div");

		/**
		 * Pomocný element pro zpracování HTML entit
		 **/
		this.decoder = document.createElement("textarea");

		/**
		 * Inicializujeme IntersectionObserver, pokud je prohlížečem podporován
		 **/
		if (window.IntersectionObserver) {
			this.observer = new IntersectionObserver(
				(entries) => {
					entries.forEach(({ isIntersecting, target }) => {
						/**
						 * Obrázek není ve viewportu nebo už je zpracovaný
						 **/
						if (!isIntersecting || target.dataset.processed) return;

						if (target.tagName.toLowerCase() === "picture") {
							Array.from(target.getElementsByTagName("source")).forEach(
								(source) => {
									source.srcset = source.dataset.srcset;
									source.removeAttribute("data-srcset");
								}
							);

							const image = target.getElementsByTagName("img")[0];

							image.src = image.dataset.src;
						} else {
							/**
							 * Nastavíme srcset pouze pokud existoval na originálním obrázku, jinak by se načítal prázdný
							 **/
							if (target.dataset.srcset) {
								target.srcset = target.dataset.srcset;
							} else {
								target.src = target.dataset.src;
							}
						}

						target.dataset.processed = "true";

						if (target.tagName.toLowerCase() === "iframe") {
							target.classList.add("loaded");
						}

						if (this.observer.unobserve) {
							this.observer.unobserve(target);
						}
					});
				},
				{
					rootMargin: this.threshold,
				}
			);
		}

		this.register(selector);
	}

	register(selector) {
		Array.from(document.querySelectorAll(selector)).forEach((item) => {
			this.decoder.innerHTML = item.innerHTML;
			this.fragment.innerHTML = this.decoder.value;
			let element = this.fragment.children[0];

			/**
			 * Pokud je IntersectionObserver podporován, nahradíme atribute před vložením elementu do DOMu
			 **/
			if (this.observer) {
				switch (element.tagName.toLowerCase()) {
					case "img":
						element = this.processImage(element);
						break;
					case "picture":
						element = this.processPicture(element);
						break;
					case "iframe":
						element = this.processFrame(element);
						break;
				}
			}

			/**
			 * Vložíme element do DOMu za původní element a ten následně odstraníme
			 **/
			item.insertAdjacentElement("afterend", element);
			item.parentNode.removeChild(item);

			if (this.observer) {
				this.observer.observe(element);
			}
		});
	}

	processPicture(picture) {
		const image = picture.getElementsByTagName("img")[0];
		const blank = LazyLoader.getPlaceholder(image.width, image.height);
		const sources = Array.from(picture.getElementsByTagName("source"));

		image._potentialSources = [];

		sources.forEach((source) => {
			image._potentialSources.push(
				image.baseURI + source.srcset.replace(/^[/]/g, "")
			);
			source.dataset.srcset = source.srcset;
			source.setAttribute("srcset", "");
		});

		image.dataset.src = image.getAttribute("src");
		image._potentialSources.push(image.src);
		image.src = blank;
		image.classList.add(`lazy-image`);

		image.onload = () => {
			if (
				image.currentSrc
					? image._potentialSources.includes(image.currentSrc)
					: image.getAttribute("src") === image.dataset.src
			) {
				image.classList.add("loaded");
				image.onload = null;
				image.removeAttribute("data-src");
				delete image._potentialSources;
			}
		};

		return picture;
	}

	processImage(image) {
		const blank = LazyLoader.getPlaceholder(image.width, image.height);
		const attribute = "srcset" in image && image.srcset ? "srcset" : "src";

		if (attribute === "srcset") {
			image.removeAttribute("src");
		}

		image.dataset[attribute] = image.getAttribute(attribute);
		image[attribute] = blank;
		image.classList.add(`lazy-image`);

		image.onload = () => {
			if (image[attribute] === image.dataset[attribute]) {
				image.classList.add("loaded");
				image.onload = null;
				image.removeAttribute(`data-${attribute}`);
			}
		};

		return image;
	}

	processFrame(frame) {
		frame.dataset.src = frame.getAttribute("src");
		frame.src = "about:blank";
		frame.classList.add(`lazy-iframe`);

		return frame;
	}

	static getPlaceholder(width, height) {
		return `data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20width='${width}'%20height='${height}'%20viewBox='0%200%20${width}%20${height}'%3E%3C/svg%3E`;
	}
}
/*
class BackgroundLazyLoader {
   constructor({ threshold = "150px" } = { threshold : "150px" } ) {
      this.threshold = threshold;

      if (window.IntersectionObserver) {
         this.observer = new IntersectionObserver(entries => {
            entries.forEach(({ isIntersecting, target }) => {

               // Element není ve viewportu nebo už je zpracovaný
               if (!isIntersecting || target.dataset.processed) return;

               target.style.backgroundImage = `url('${target.dataset.lazyBg}')`;
               target.removeAttribute("data-lazy-bg");

               target.dataset.processed = "true";

               if (this.observer.unobserve) {
                  this.observer.unobserve(target);
               }
            });
         }, {
            rootMargin: this.threshold
         });
      }

      document.querySelectorAll("[data-lazy-bg]").forEach(item => {
         if (this.observer) {
            this.observer.observe(item);
         } else {
            item.style.backgroundImage = `url('${item.dataset.lazyBg}')`;
         }
      });
   }
} */

new LazyLoader();
//new BackgroundLazyLoader();
